import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useTitleSmsList() {
    // Use toast
    const toast = useToast();

    const refTitleSmsListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "id", label: "#"},
        { key: "title", label: "Title" },
        { key: "status", label: "Trạng thái" },
        // { key: "created_by", label: i18n.t("Created by") },
        { key: "created_at", label: i18n.t("Created at") },
        // { key: "updated_by", label: i18n.t("Updated by") },
        { key: "updated_at", label: i18n.t("Ngày cập nhật") },
        { key: "action", label: i18n.t("Actions") },
    ];
    const perPage = ref(25);
    const totalAdjustmentReason = ref(0);
    const currentPage = ref(1);
    const idFilter = ref(null);

    const refetchData = () => {
        refTitleSmsListTable.value.refresh();
    };

    watch([currentPage], () => {
        refetchData();
    });

    const fetchTitleSmsList = (ctx, callback) => {
        store
            .dispatch("title-sms/fetchTitleSmsList", {
                page: currentPage.value,
                id: idFilter.value,
            })
            .then((response) => {
                callback(response.data.data.sort((a, b) => a.id - b.id));
                totalAdjustmentReason.value = response.data.count;
                perPage.value = currentPage === 1 ? response.data.data.length : 25;
            })
            .catch((error) => {
                const { response } = error;
                toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message || "Error fetching transfers list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    const dataMeta = computed(() => {
        const localItemsCount = refTitleSmsListTable.value
            ? refTitleSmsListTable.value.localItems.length
            : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalAdjustmentReason.value,
        };
    });

    return {
        fetchTitleSmsList,
        tableColumns,
        perPage,
        currentPage,
        totalAdjustmentReason,
        dataMeta,
        refTitleSmsListTable,
        refetchData,
        idFilter,
    };
}
