import ReasonService from "@/libs/reason.service";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchTitleSmsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                ReasonService.getListTitleSms(queryParams)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addTitleSms(ctx, dataInput) {
            return new Promise((resolve, reject) => {
                ReasonService.addNewTitleSms(dataInput)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        editTitleSms(ctx, dataInput) {
            return new Promise((resolve, reject) => {
                ReasonService.updateTitleSms(dataInput)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteTitleSms(ctx, dataInput) {
            return new Promise((resolve, reject) => {
                ReasonService.deleteTitleSms(dataInput)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }
    },
};
