<template>
  <div>
    <b-modal
        id="modal-add-new-title"
        cancel-variant="outline-secondary"
        :ok-title="$t('Add')"
        :cancel-title="$t('Close')"
        ok-variant="success"
        centered
        modal-class="modal-success"
        :title="$t('Add A New Title')"
        @ok="confirmAdd"
        @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="addNewTitleSmsModal">
        <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
        >
          <!-- Field: Content -->
          <b-form-group label-for="title">
            <label class="mb-1">{{ $t("Title") }}</label>
            <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
            >
              <b-form-textarea
                  id="title"
                  v-model="newTitle.title"
                  rows="4"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  data() {
    return {
      newTitle: {
        title: "",
      },
    };
  },
  methods: {
    validationForm() {
      this.$refs.addNewTitleSmsModal.validate().then((success) => {
        if (success) {
          this.addNewTitle(this.newTitle);
        }
      });
    },
    async addNewTitle(newTitle) {
      await this.$store
          .dispatch("title-sms/addTitleSms", newTitle)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === "00") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "success",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$emit("refetch-data");
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvModal.hide("modal-add-new-title");
                    this.resetModal();
                  }, "500");
                });
                this.$emit("refetch-data");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            }
          });
    },
    resetModal() {
      this.newTitle = {
        title: "",
      };
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {};
  },
};
</script>