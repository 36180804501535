<template>
  <div>
    <div class="p-1">
      <b-row>
        <b-col v-if="$can('create', 'smstitle')" class="col d-flex justify-content-end">
          <b-button variant="primary" @click="addNewTitle">
            <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-card no-body class="mb-0">
      <b-table ref="refTitleSmsListTable" class="position-relative table-white-space max-height-table" sticky-header
        head-variant="light" :no-border-collapse="true" :items="fetchTitleSmsList" responsive :fields="tableColumns"
        primary-key="id" show-empty empty-text="No matching records found">
        <template #cell(text_msg)="data">
          <div style="max-width: 300px; white-space: pre-wrap">
            {{ data.item.title }}
          </div>
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label
            }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at ? formatDateTime(data.item.created_at).replace(".000000Z", "") : '' }}
        </template>

        <template #cell(updated_at)="data">
          {{ data.item.updated_at ? formatDateTime(data.item.updated_at).replace(".000000Z", "") : '' }}
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-button v-b-tooltip.hover.v-info :title="$t('Edit')" v-if="$can('update', 'smstitle')" variant="primary"
            class="btn-icon btn-sm mr-50" @click="updateNewTitle(data.item)">
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalAdjustmentReason" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add adjustmentReason Modal -->
    <AddNewTitleSmsModal @refetch-data="refetchData" />

    <!-- Update adjustmentReason Modal -->
    <UpdateTitleSmsModal @refetch-data="refetchData" :titleSmsDetail.sync="titleSmsDetail"/>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  VBTooltip
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  resolveCurrency,
  formatDateTime,
} from "@core/utils/filter";
import store from "@/store";
import titleSmsStoreModule from "@/views/reason/title-sms/titleSmsStoreModule";
import useTitleSms from "@/views/reason/title-sms/useTitleSms";
import AddNewTitleSmsModal from "@/views/reason/title-sms/AddNewTitleSmsModal";
import UpdateTitleSmsModal from "@/views/reason/title-sms/UpdateTitleSmsModal";

export default {
  components: {
    AddNewTitleSmsModal,
    UpdateTitleSmsModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      titleSmsDetail: null,
      // type: null,
    };
  },
  methods: {
    addNewTitle() {
      this.$bvModal.show('modal-add-new-title')
    },
    updateNewTitle(data) {
      this.titleSmsDetail = data;
      this.$bvModal.show('modal-update-title')
    },
  },
  setup() {
    if (!store.hasModule("title-sms"))
      store.registerModule("title-sms", titleSmsStoreModule);
    onUnmounted(() => {
      if (store.hasModule("title-sms")) store.unregisterModule("title-sms");
    });

		const resolveStatus = status => {
			if (status == 1) return { label: 'Active', variant: 'primary' }
			if (status == 2) return { label: 'Inactive', variant: 'danger' }
			return { label: 'None', variant: 'secondary' }
		}

    const {
      fetchTitleSmsList,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustmentReason,
      dataMeta,
      refTitleSmsListTable,
      refetchData,
      idFilter,
    } = useTitleSms();


    return {
      fetchTitleSmsList,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustmentReason,
      dataMeta,
      refTitleSmsListTable,
      refetchData,
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      idFilter,
      resolveStatus,
    };
  },
};
</script>
<style scoped>
.max-height-table {
  max-height: 580px;
}
</style>