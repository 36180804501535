/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from "@core/utils/filter";
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class ReasonService {
  getAdjustmentReasonLists(queryParams) {
    const pars = {
      id: queryParams.id,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/adjust-balance-reason/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  addAdjustmentReason(adjustmentReasonData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", adjustmentReasonData.name);
    data.append("is_bonus", adjustmentReasonData.isBonus);

    return instance.post("/api/adjust-balance-reason/create", data, {
      headers: authHeader(),
    });
  }
  updateAdjustmentReason(adjustmentReasonData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", adjustmentReasonData.id);
    data.append("name", adjustmentReasonData.name);
    data.append("is_bonus", adjustmentReasonData.isBonus);
    return instance.post("/api/adjust-balance-reason/update", data, {
      headers: authHeader(),
    });
  }
  deleteAdjustmentReason(adjustmentReasonData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", adjustmentReasonData.id);

    return instance.post("/api/adjust-balance-reason/delete", data, {
      headers: authHeader(),
    });
  }

  getListTemplateSms(queryParams) {
    const pars = {
      id: queryParams.id,
    };
    return instance.get("/api/message-template/index", {
      headers: authHeader(),

      params: pars,
    });
  }
  addNewTemplateSms(dataInput) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("content", dataInput.content.trim());

    return instance.post("/api/message-template/create", data, {
      headers: authHeader(),
    });
  }

  updateTemplateSms(dataInput) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("content", dataInput.content.trim());
    data.append("id", dataInput.id);

    return instance.post("/api/message-template/update", data, {
      headers: authHeader(),
    });
  }

  getListTitleSms(queryParams) {
    const pars = {
      id: queryParams.id,
    };
    return instance.get("/api/message-title/index", {
      headers: authHeader(),

      params: pars,
    });
  }

  addNewTitleSms(dataInput) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("title", dataInput.title.trim());

    return instance.post("/api/message-title/create", data, {
      headers: authHeader(),
    });
  }

  updateTitleSms(dataInput) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("title", dataInput.title.trim());
    data.append("status", dataInput.status);
    data.append("id", dataInput.id);

    return instance.post("/api/message-title/update", data, {
      headers: authHeader(),
    });
  }

  deleteTitleSms(dataInput) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", dataInput.id);

    return instance.post("/api/message-title/delete", data, {
      headers: authHeader(),
    });
  }
}

export default new ReasonService();
